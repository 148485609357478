/* --------------------------
フッター固定ボタン
-------------------------- */

.p-fixedmenu {
  display: none;

  @include view-at(sp) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    padding: 4px;
  }

  &-list {
    display: flex;
    margin: -2px;

    &-item {
      width: 25%;
      padding: 2px;

      a {
        display: block;
        text-decoration: none;
        border: 1px solid $color-black;
        padding: 6px 0 4px;
        font-size: 2.4vw;
        height: 100%;

        span {
          position: relative;
          padding-left: 1em;
          &::after {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            display: block;
            content: "\f101";
            font-family: "Font Awesome 5 Free";
            font-size: 0.5rem;
            font-weight: 900;
          }
        }
      }

      &--request {
        a {
          background: $color-black;
          color: $color-white;
        }

        .p-fixedmenu-contents {
          &::before {
            background: $color-white;
          }
        }
      }
    }
  }
  &-contents {
    text-align: center;
    &::before {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      margin-bottom: 4px;
      background: $color-black;
    }
  }
}

// .p-fixedmenu {
//   position: fixed;
//   bottom: 0;
//   width: 100%;
//   margin: 0;
//   padding: 0;
//   background: #fff;
//   z-index: 100;
// }
// @media screen and (min-width: 768px), print {
//   .p-fixedmenu {
//     display: none;
//   }
// }
// .p-fixedmenu__pagetop {
//   margin-bottom: 0;
//   background: #000;
// }
// .p-fixedmenu__pagetop > a {
//   display: block;
//   height: 40px;
//   margin: 0;
//   background: url(../images/common/cmn_label_arrow_top.png) center center/25px
//     13px no-repeat;
//   text-indent: -9999px;
//   overflow: hidden;
// }
// .p-fixedmenu__list {
//   margin: 0;
//   padding: 0 4px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   list-style: none;
// }
// .p-fixedmenu__list > li {
//   width: 25%;
//   height: 20vw;
//   padding: 8px 4px;
//   font-size: 2.4vw;
// }
// .p-fixedmenu__list > li > a {
//   position: relative;
//   display: block;
//   width: 100%;
//   height: 100%;
//   padding-top: 9.6vw;
//   border: 1px solid #333;
//   color: #333;
//   text-decoration: none;
// }
// .p-fixedmenu__list > li > a span {
//   position: relative;
//   padding-left: 0.9em;
// }
// .p-fixedmenu__list > li > a span:after {
//   position: absolute;
//   top: 50%;
//   left: 0;
//   display: block;
//   content: "\f101";
//   font-family: "Font Awesome 5 Free";
//   font-size: 1.8vw;
//   font-weight: 900;
//   -webkit-transform: translateY(-50%);
//   transform: translateY(-50%);
// }

// .p-fixedmenu__item--tel > a:after {
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: block;
//   width: 100%;
//   height: 100%;
//   content: "";
//   background: url(../images/common/cmn_icon_tel.svg) center 2.7vw/4.8vw 4.8vw
//     no-repeat;
// }

// .p-fixedmenu__item--outline > a:after {
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: block;
//   width: 100%;
//   height: 100%;
//   content: "";
//   background: url(../images/common/cmn_icon_outline.svg) center 2.9vw/5.3vw
//     4.2vw no-repeat;
// }

// .p-fixedmenu__item--map > a:after {
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: block;
//   width: 100%;
//   height: 100%;
//   content: "";
//   background: url(../images/common/cmn_icon_map.svg) center 2.5vw/3.3vw 4.9vw
//     no-repeat;
// }

// .p-fixedmenu__item--req {
//   a {
//     background: $color-black;
//     color: $color-white !important;
//   }
// }

// .p-fixedmenu__item--req > a:after {
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: block;
//   width: 100%;
//   height: 100%;
//   content: "";
//   background: url(../images/common/cmn_icon_req-white.svg) center 3vw/5vw 3.8vw
//     no-repeat;
// }
// .p-fixedmenu__item--res > a:after {
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: block;
//   width: 100%;
//   height: 100%;
//   content: "";
//   background: url(../images/common/cmn_icon_res.svg) center 2.5vw/4.5vw 5vw
//     no-repeat;
// }

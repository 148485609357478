/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);

  &-inner {
    max-width: $contents-width;
    width: 100%;
    margin: 0 auto;
    padding: 15px $contents-padding 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include view-at(sp) {
      padding: 10px $contents-padding 10px;
    }
  }

  &-logo {
    text-align: left;

    span {
      display: none;
    }

    a {
      display: inline-block;
      line-height: 1;

      @include view-at(pc) {
        img {
          width: auto;
          height: 45px;
        }
      }

      @include view-at(sp) {
        img {
          width: auto;
          height: 30px;
        }
      }
    }
  }
}

/* --------------------------
フッター
-------------------------- */

.p-footer {
  &-cta {
    padding: 100px 0;
    background-color: $color-gray;

    &-list {
      display: flex;
      justify-content: center;
      align-items: center;

      @include view-at(sp) {
        flex-wrap: wrap;
        margin: -10px;
      }

      &-item {
        padding: 20px;

        @include view-at(sp) {
          padding: 10px;
          width: 100%;
        }

        a {
          display: block;
          min-width: 350px;
          font-size: rem(24px);
          line-height: 1;
          text-align: center;
          color: $color-black;
          border: 5px double $color-black;
          padding: 15px 20px 10px;
          text-decoration: none;
        }
      }
    }
    &-tel {
      max-width: 450px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      padding: 20px 40px;
      border-top: 5px solid $color-black;
      border-bottom: 5px solid $color-black;
      margin-bottom: 50px;

      a {
        display: inline-block;

        font-size: rem(40px);
        letter-spacing: 0.1em;
        text-decoration: none;
      }
    }
  }

  &-logo {
    margin: 50px 0;
    text-align: center;
  }
  &-copyright {
    text-align: center;
    background-color: $color-gray;
    color: $color-white;
    padding: 10px;
    line-height: 1;
    font-size: rem(12px);
  }
}

.ambassador {
  &-panel,
  &-h4 {
    background: #dff1f8;
  }

  &-section {
    color: #50a9b5;
  }

  &-button {
    background: #b6dbe2;
  }
}

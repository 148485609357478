/* --------------------------
リンク
-------------------------- */

.c-link {
  color: $color-black;

  &--block {
    display: block;
    text-decoration: none;

    img {
      display: block;
    }
  }

  &--border{
    text-decoration: underline;
    
  }
}

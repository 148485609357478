.top {
  &-fixed {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;

    // &-button {
    //   max-width: 670px;
    //   margin: 0 auto;
    // }
  }

  &-illust {
    text-align: center;
    margin-bottom: 20px;
  }

  &-section {
    font-size: rem(25px);
    color: #f0844a;
    font-style: italic;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 40px;
  }

  &-bedroom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-item {
      width: 100%;
    }
  }

  &-image {
    text-align: center;
    margin: 40px 0;

    &--icon {
      img {
        width: 50%;
      }
    }

    @include view-at(sp) {
      margin: 20px 0;
    }
  }
}

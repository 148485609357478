/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
  @include view-at(sp) {
    display: none;
  }

  &.active {
    display: block;
  }

  &-list {
    display: flex;
    max-width: $contents-width;
    margin: 0 auto;

    @include view-at(sp) {
      flex-wrap: wrap;
      justify-content: flex-start;
      text-align: center;
    }

    &-item {
      padding: 0;
      flex-grow: 1;
      position: relative;

      @include view-at(sp) {
        padding: 0;
        width: 50%;
        flex-grow: 0;
        border-bottom: 1px solid $color-gray;
      }

      &:nth-child(2n-1) {
        @include view-at(sp) {
          border-right: 1px solid $color-gray;
        }
      }

      &.current {
        a {
          border-bottom: 5px solid $color-gray-light;
        }
        .p-gnav-drop {
          a {
            border-bottom: 3px solid $color-gray-light;
          }
        }
      }

      &.dropdown {
        &:hover {
          .p-gnav-drop {
            display: block;

            a {
              &:hover {
                border-bottom: 3px solid $color-gray-light;
              }
            }
          }
        }

        @include view-at(tab) {
          &:hover {
            .p-gnav-drop {
              display: none;
            }
          }
        }
      }

      a {
        font-size: rem(16px);
        border-bottom: 5px solid transparent;
        line-height: 1;
        padding: 15px 0 20px;
        display: block;
        text-align: center;
        text-decoration: none;
        @include view-at(sp) {
          height: auto;
          padding: 15px 0 10px;
          line-height: 1;
        }

        &:hover {
          border-bottom: 5px solid $color-gray-light;
        }
      }
    }
  }
}

.p-sns {
  position: fixed;
  bottom: 125px;
  right: 35px;

  @include view-at(sp) {
    display: none;
  }

  &-title {
    width: 25px;
    display: flex;
    writing-mode: vertical-lr;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    letter-spacing: 0.05em;
    font-weight: $bold;
    margin-bottom: 75px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -60px;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: block;
      width: 1px;
      height: 45px;
      background: $color-black;
    }
  }

  &-list {
    margin: -7.5px 0;

    &-item {
      padding: 7.5px 0;

      a {
        display: block;
        text-decoration: none;
        font-size: 1.5rem;
      }
    }
  }
}

.p-modal {
  display: none;
  position: fixed;
  z-index: 1000; /*サイトによってここの数値は調整 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(30, 30, 30, 0.9);
  }

  &-contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 900px;
    padding: 10px;
    background-color: #fff;

    img{
      display: block;
      width: 100%;
    }
  }

  &-close {
    font-size: rem(32px);
    color: $color-white;
    position: fixed;
    bottom:100%;
    left: 100%;
    cursor: pointer;
    z-index: 1001;
  }
}

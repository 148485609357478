/* --------------------------
テキスト装飾
-------------------------- */

.u-text-accent {
  color: $color-black;
}

.u-text-important {
  color: $color-black;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-sub {
  color: $color-gray;
}

.u-text-serif {
  font-family: "Kokoro",serif;  
}

.u-text-bold {
  font-weight: $bold;
}

.u-text-normal {
  font-weight: $normal;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, #f08080 70%);
}

.u-text-x-small {
  font-size: rem(12px);
  line-height: 1.2;
}

.u-text-small {
  font-size: rem(14px);
  line-height: 1.4;
}

.u-text-default {
  font-size: rem(16px);
  line-height: 1.6;
}

.u-text-large {
  font-size: rem(20px);
  line-height: 1.6;
}

.u-text-x-large {
  font-size: rem(25px);
  line-height: 1.8;
}

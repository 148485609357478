.p-caution {
  font-size: rem(16px);
  line-height: 1.4;
  padding-left: 15px;
  position: relative;

  @include view-at(sp) {
    font-size: rem(12px);
  }

  &::before {
    content: "※";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

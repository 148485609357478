.p-mv {
  height: 300px;
  background-color: $color-gray;
  background-position: center center;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @include view-at(sp) {
    height: 150px;
  }
}

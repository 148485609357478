html {
  font-size: 20px;
}

body {
  font-feature-settings: "palt";
  font-family: "ヒラギノ角ゴシック", "Hiragino Sans", YuGothic, "Yu Gothic",
    "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ＭＳ ゴシック",
    "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 300;
  color: $color-black;
  font-size: rem(20px);
  background: $color-gray-light;

  @include view-at(sp) {
    font-size: rem(15px);
  }
}

a {
  color: $color-black;
  text-decoration: none;

  &:hover {
    opacity: 0.6;

    img {
      opacity: 0.6;
    }
  }
}

p {
  margin-bottom: 1em;
  font-weight: $normal;
  line-height: 1.6;
  letter-spacing: 0.1em;

  &:last-child {
    margin-bottom: 0;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  max-width: $contents-width;
  width: 100%;
  margin: 0 auto;
  padding: 0 $contents-padding;

  &-contents {
    background: $color-white;
    padding: 20px;
  }
}

/* --------------------------
color
-------------------------- */

$color-white: #fff;
$color-black: #000;

$color-beige: #fef4e3;
$color-beige-dark: #fce0b9;

$color-gray: #a2a2a2;
$color-gray-light: #f2f2f3;
$color-gray-dark: #696969;
$color-gray-snow: #eeeeee;

/* --------------------------
spacing
-------------------------- */
$space: 80px, 40px, 30px, 20px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 40px, 30px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$bold: 700;
$normal: 300;
/* --------------------------
contents width
-------------------------- */
$contents-width: 1080px;

$contents-padding: 20px;
$contents-padding-sp: 25px;

/* --------------------------
z-index order
-------------------------- */
$z: (contents, arrow, gnav, leftbar, header, footer, modal);

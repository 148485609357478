/* --------------------------
リスト
-------------------------- */

.c-list {
  font-size: rem(16px);

  > li {
    line-height: 1.4;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--point {
    li {
      margin-bottom: 5px;
      padding-left: 20px;

      &::before {
        content: "";
        width: 14px;
        height: 14px;
        border: 1px solid $color-black;
        border-radius: 100%;
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 0;
      }

      &.note {
        &::before {
          content: none;
        }
      }
    }
  }
}

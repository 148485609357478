/* --------------------------
エクストラナビ（Gナビとは別にヘッダー内に表示するナビゲーション）
-------------------------- */

.p-exnav {
  @include view-at(sp) {
    display: none;
  }
  &-list {
    margin: 0 -8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-item {
      padding: 0 8px;

      a {
        display: block;
        text-decoration: none;
      }

      &--text {
        &::before {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 8.7px;
          border-color: transparent transparent transparent $color-gray;
          padding-right: 0.5em;
        }
      }

      &--button {
        width: 150px;
        background: $color-black;
        color: $color-white;
        padding: 10px 0;
        text-align: center;
        position: relative;
      }
    }
  }

  &-drop {
    position: relative;
    &:hover {
      .p-exnav-drop-list {
        display: block;
      }
    }
    &-list {
      display: none;
      position: absolute;
      padding: 0 8px;
      top: 100%;
      left: 0;
      z-index: 100;

      &-item {
        border-top: 1px solid $color-white;
        a {
          width: 150px;
          background: $color-black;
          color: $color-white;
          padding: 10px 0;
          padding: 10px 0;
          text-align: center;
        }
      }
    }
  }
}

/* --------------------------
見出し
-------------------------- */

.c-h1 {
  font-size: rem(50px);
  font-weight: $bold;
  color: $color-white;
  letter-spacing: 0.1em;
}

.c-h2 {
  font-size: rem(35px);
  line-height: 1.4;
  letter-spacing: 0.1em;
  margin-bottom: 40px;
  // 以下装飾
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid $color-black;
  border-bottom: 1px solid $color-black;

  @include view-at(sp) {
    font-size: rem(20px);
    margin-bottom: 20px;
  }
}

.c-h3 {
  font-family: "Kokoro", serif;
  font-size: rem(30px);
  line-height: 1.4;
  letter-spacing: 0.1em;
  margin-bottom: 40px;
  // 以下装飾
  text-align: center;

  @include view-at(sp) {
    font-size: rem(20px);
    margin-bottom: 20px;
  }
}

.c-h4 {
  font-size: rem(20px);
  letter-spacing: 0.1em;
  line-height: 1.2;
  margin-bottom: 40px;
  // 以下装飾
  padding: 10px;
  background: $color-beige;
  text-align: center;

  @include view-at(sp) {
    font-size: rem(18px);
    padding: 10px;
    margin-bottom: 20px;
  }
}

.c-h5 {
  font-size: rem(20px);
  letter-spacing: 0.1em;
  line-height: 1.2;
  margin: 10px 0;
  // 以下装飾

  @include view-at(sp) {
    font-size: rem(18px);
    margin: 10px 0;
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}

.online {
  &-header {
    padding: 100px 0 50px;
    background: #66413a;
    text-align: center;

    @include view-at(sp) {
      padding: 50px 0 25px;
    }

    img {
      width: 400px;

      @include view-at(sp) {
        width: 200px;
      }
    }
  }

  &-footer {
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: rem(18px);
    letter-spacing: 0.1em;
    color: $color-white;
    padding: 50px 0 50px;
    background: #66413a;
    text-align: center;

    @include view-at(sp) {
      font-size: rem(15px);
      padding: 50px 0 35px;
    }
  }

  &-main {
    background: #f4f2e6;
    padding: 50px 0 50px;

    @include view-at(sp) {
      padding: 25px 0 50px;
    }
  }

  &-container {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px;

    @include view-at(sp) {
      max-width: 100%;
    }
  }

  &-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include view-at(tab) {
      flex-wrap: wrap-reverse;
    }
  }

  &-item {
    width: 100%;
    padding-right: 80px;

    @include view-at(tab) {
      width: 100%;
      padding-right: 0;
      margin-top: 50px;
    }

    @include view-at(sp) {
      margin-top: 25px;
    }
  }

  &-illust {
    width: 500px;

    @include view-at(tab) {
      width: 50%;
      margin: 0 auto;
    }

    img {
      mix-blend-mode: multiply;
    }
  }

  &-title {
    margin-bottom: 80px;

    img {
      height: 40px;

      @include view-at(sp) {
        height: auto;
      }
    }

    @include view-at(tab) {
      margin-bottom: 50px;
    }

    @include view-at(sp) {
      margin-bottom: 25px;
    }
  }

  &-text {
    font-size: rem(20px);
    line-height: 2.4;
    letter-spacing: 0.1em;
    text-align: justify;
    margin-bottom: 2em;

    @include view-at(sp) {
      font-size: rem(18px);
      letter-spacing: 0.05em;
      line-height: 2;
    }
  }

  &-button {
    display: inline-block;
    width: 500px;
    font-size: rem(30px);
    font-weight: 500;
    color: $color-white;
    text-align: center;
    padding: 15px 0 20px;
    line-height: 1.7;
    letter-spacing: 0.4em;
    background: #ba8e85;

    @include view-at(tab) {
      width: 100%;
    }

    @include view-at(sp) {
      font-size: rem(25px);
    }
  }

  &-caption {
    margin-top: 100px;

    @include view-at(sp) {
      margin-top: 50px;
    }

    > li {
      font-size: rem(14px);
      line-height: 1.75;
      letter-spacing: 0.1em;
      padding-left: 1em;
      position: relative;

      @include view-at(sp) {
        font-size: rem(16px);
      }

      &::before {
        content: "※";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

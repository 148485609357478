/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: block;
  padding: 15px;
  font-family: "Kokoro",serif;  
  font-size: rem(30px);
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-align: center;
  color: $color-black;
  background-color: $color-beige-dark;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @include view-at(sp) {
    font-size: rem(15px);
  }
}

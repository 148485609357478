/* --------------------------
背景色
-------------------------- */

.u-bg-light {
  background-color: $color-gray-snow;
}

.u-bg-white {
  background-color: $color-white;
}

.u-bg-black {
  background-color: $color-black;
}

.p-news {
  width: 100%;
  max-width: 750px;
  padding: 0 20px;
  margin: 0 auto;

  &-title {
    font-size: rem(28px);
    letter-spacing: 0.03em;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    align-items: center;

    &::before,
    &::after {
      content: "";
      flex-grow: 1;
      height: 1px;
      background: $color-gray-light;
      display: block;
    }

    &::before {
      margin-right: 20px;
      @include view-at(sp) {
        margin-right: 10px;
      }
    }

    &::after {
      margin-left: 20px;
      @include view-at(sp) {
        margin-left: 10px;
      }
    }

    @include view-at(sp) {
      margin-bottom: 20px;
    }
  }

  &-list {
    margin: -10px 0;
    // height: 100px;
    // overflow-y: scroll;

    @include view-at(sp) {
      margin: -20px 0;
      // height: 150px;
    }
    &-item {
      display: flex;
      align-items: center;
      padding: 15px 40px;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        padding: 20px 0;
        flex-wrap: wrap;
      }

      // &:last-child {
      //   border-bottom: none;
      // }
    }
  }

  &-date {
    flex-shrink: 0;
    margin-right: 40px;

    @include view-at(sp) {
      width: 100%;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}

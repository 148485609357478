/* --------------------------
フッター
-------------------------- */

.p-fnav {
  border-top: 1px solid $color-black;
  border-bottom: 1px solid $color-black;
  padding: 10px 0;
  margin-bottom: 50px;

  &-list {
    margin: 0 -20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include view-at(sp) {
      flex-wrap: wrap;
    }

    &-item {
      flex-shrink: 1;
      padding: 0 20px;
      border-right: 1px solid $color-black;

      &:first-child {
        border-left: 1px solid $color-black;
      }

      a {
        display: block;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
